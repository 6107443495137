<script>
import nav_drawerHeader from '@/components/nav/nav_drawerHeader.vue'; 
import nav_list from '@/components/nav/nav_list.vue';

export default {
    props: {
        initialRender: Boolean,
        items: Array, // items를 props로 받아옴
    },
    components: {
        nav_drawerHeader,
        nav_list,
    },
    methods: {
        toggleDrawer() {
            this.$emit('toggleDrawer');
        },
    },
data() {
    return {
        right: null,
        drawer: true,
        showdrawer: true,
        };
    },

    watch: {
        $route(to, from) {
        this.showdrawer = to.name !== 'login';
        },
        drawer(newValue) {
        if (newValue) {
            // 좌측 메뉴가 열렸을 때, 초기 렌더링 상태를 false로 변경
            this.initialRender = false;
        }
        },
    },
};
</script>

<template>
<v-navigation-drawer
    v-show="drawer || initialRender"
    v-model="drawer"
    v-bind="$attrs"
    app
    @toggleDrawer="toggleDrawer"
    v-if="showdrawer">
    <nav_drawerHeader/>
    <v-divider />
    <nav_list :items="items"/>
</v-navigation-drawer>
</template>
