<script>
export default {
    props: {
    items: Array, // items를 props로 받아옴
    },
data() {
return {
    internalItems: [
    { title: 'Dashboard', icon: 'mdi-view-dashboard', route: '/dashboard' },
    { title: 'Service', icon: 'mdi-image', route: '/service' },
    {
        title: 'Support',
        icon: ' mdi-comment-question',
        children: [
        { title: 'Open New Support Ticket',  route: '/Ticket' },
        { title: 'Tickes Archive', route: '/Tickets_list' },
        { title: 'View / Search Articels',  route: '/Search_Articles' },
        { title: 'Browse downloads library',  route: '/Downloads' },
        { title: 'Annoucements',  route: '/Annoucements' },
        { title: 'FreeDNS Order',  route: '/New_Order' },
        ],
    },
    {   title: 'Affiliates',
        icon: 'mdi-pencil',
        route:'/Affiliates/Summary'
    },
    {   title: 'Account',
        icon: 'mdi-help-box',
        children: [
        { title: 'Edit Details',  route: '/Affiliates/Affiliate_Details' },
        // { title: 'Change password', route: '/Tickets_list' },
        // { title: 'Manage contacts',  route: '/Search_Articles' },
        // { title: 'Security',  route: '/Downloads' },
        { title: 'Invoices',  route: '/Account/Invoices' },
        { title: 'Logs',  route: '/Account/logs' },
        ],
    },
    ],
};
},
};
</script>

<template>
    <!-- Main items with potential sub-menus -->
    <v-list dense nav>
    <v-list-item
        v-for="item in internalItems"
        :key="item.title"
        v-if="!item.children"
        
        :to="item.route"
        link
    >
        <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>

    <!-- Sub-menus -->
    <v-list-group
        v-else
        :key="item.title"
        :prepend-icon="item.icon"
        color="black"
    >
        <template v-slot:activator>
        <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        </template>

        <v-list-item
        v-for="child in item.children"
        :key="child.title"
        :to="child.route"
        link
        color="black"
        >
        <v-list-item-icon>
            <v-icon>{{ child.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
    </v-list-group>
    </v-list>
</template>