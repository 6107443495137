<template>
    <v-app-bar app class="pa-0" color="#2e3138" dark>
        <v-img v-if="showLogo" src="@/assets/kdatacenterlogo.png" />
        <v-app-bar-nav-icon @click="toggleDrawer" v-if="showHeader"/>
        <nav_header />
        <!-- <nav_drawer :drawer="drawer" :items="items" @toggleDrawer="toggleDrawer" /> -->
    </v-app-bar>
</template>

<script>
import nav_header from '@/components/nav/nav_header.vue'; 
import nav_drawerHeader from '@/components/nav/nav_drawerHeader.vue'; 
import nav_drawer from '@/components/nav/nav_drawer.vue'; 

export default {
    components: {
    nav_header,
    nav_drawerHeader,
    nav_drawer,
},
data() {
        return {
            showHeader: true,
            showLogo: false,
            right: null,
            drawer: false,
        };
    },
    methods: {
    toggleDrawer() {
        console.log('toggleDrawer method called');
        this.drawer = !this.drawer;
        console.log('Drawer value:', this.drawer);
    },
},
    watch: {
    $route(to, from) {
        this.showHeader = to.name !== 'login';
        this.showLogo = to.name === 'login';
        },
    },
}
</script>