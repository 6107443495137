<template>
  <v-app id="app">
    <nav_bar/>
    <nav_drawer :drawer="drawer" @toggleDrawer="toggleDrawer" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import nav_bar from '@/components/nav/nav_bar.vue';
import nav_drawer from '@/components/nav/nav_drawer.vue'; 

export default {
  components: {
  nav_bar,
  nav_drawer,
},
data() {
    return {
      drawer: false,
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
}
</script>
