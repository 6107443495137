import Vue from 'vue'
import App from './App.vue'
import router from "./router/router"
import vuetify from './plugins/vuetify'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

library.add(faGoogle);

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
