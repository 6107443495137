<template>
    <v-list-item>
        <v-row v-if="showHeader">
            <v-col  class="pt-4 pb-2">
                <router-link to="/Notices">
                    <v-btn dark class="k_btn_medium mr-2 mt-1">
                        <v-icon small>
                            mdi mdi-bell
                        </v-icon>
                        Announcements
                    </v-btn>
                </router-link>
                <router-link to="/Support/Order">
                    <v-btn dark class="k_btn_medium mr-2 mt-1">
                    <v-icon small>
                        mdi mdi-plus
                    </v-icon>
                    Add New Oreder
                </v-btn>
                </router-link>
                <router-link to="/Affiliates/Affiliate_Details">
                    <v-btn dark class="k_btn_medium mt-1">
                        <v-icon small>
                            mdi mdi-account-cog
                        </v-icon>
                        Account
                    </v-btn>
                </router-link>
            </v-col>

            <v-col  class="text-right">
                <router-link to="/Tickets_list">
                <v-btn  icon dark>
                <v-icon small>
                    mdi mdi-email
                </v-icon>
            </v-btn>
            </router-link>
            <router-link to="/Account/Invoices">
                <v-btn  icon dark>
                <v-icon small>
                    mdi mdi-file-document-outline
                </v-icon>
            </v-btn>
            </router-link>
            <router-link to="/Support/Order">
            <v-btn  icon dark>
                <v-icon small>
                    mdi mdi-cart
                </v-icon>
            </v-btn>
            </router-link>
            <router-link to="/login">
                <v-btn dark class="k_btn_medium">
                    Login
                </v-btn>
            </router-link>
            </v-col>
        </v-row>
</v-list-item>

</template>

<script>
export default {
    data: () => ({
        showHeader: true,
        drawer: false,
    }),

    watch: {
    $route(to, from) {
        this.showHeader = to.name !== 'login';
        },
    },

    methods: {
    toggleDrawer() {
        this.$emit('toggle-drawer'); // 이벤트를 발생시킴
        },
    } ,
}
</script>

<style scoped>
.k_btn_medium{
    height: 30px !important;
    font-size: 10px;
    box-shadow: none;
    text-transform: none;
}

</style>