// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const route = [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard/dashboard.vue')
    },
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/Service/Service.vue')
    },
    {
        path: '/Notices',
        name: 'Notices',
        component: () => import('../views/Support/Notices.vue')
    },
    {
        path: '/Ticket',
        name: 'Ticket',
        component: () => import('../views/Support/Ticket.vue')
    },
    {
        path: '/Tickets_list',
        name: 'Tickets_list',
        component: () => import('../views/Support/Tickets_list.vue')
    },
    {
        path: '/Downloads',
        name: 'Downloads',
        component: () => import('../views/Support/Downloads.vue')
    },
    {
        path: '/Annoucements',
        name: 'Annoucements',
        component: () => import('../views/Support/Annoucements.vue')
    },
    {
        path: '/Support/Annoucements_view',
        name: 'Annoucements_view',
        component: () => import('../views/Support/Annoucements_view.vue')
    },
    {
        path: '/Search_Articles',
        name: 'Search_Articles',
        component: () => import('../views/Support/Search_Articles.vue')
    },
    {
        path: '/New_Order',
        name: 'New_Order',
        component: () => import('../views/Support/New_Order.vue')
    },
    {
        path: '/Support/Order',
        name: 'Order',
        component: () => import('../views/Support/Order.vue')
    },
    {
        path: '/Affiliates/Summary_create',
        name: 'Summary',
        component: () => import('../views/Affiliates/Summary_create.vue')
    },
    {
        path: '/Affiliates/Commissions',
        name: 'Commissions',
        component: () => import('../views/Affiliates/Commissions.vue')
    },
    {
        path: '/Affiliates/Affiliate_Details',
        name: 'Affiliate Details',
        component: () => import('../views/Affiliates/Affiliate_Details.vue')
    },
    {
        path: '/Affiliates/Summary',
        name: 'Summary',
        component: () => import('../views/Affiliates/Summary.vue')
    },
    {
        path: '/Affiliates/Summary',
        name: 'Summary',
        component: () => import('../views/Affiliates/Summary_create.vue')
    },
    {
        path: '/Account/Invoices',
        name: 'Invoices',
        component: () => import('../views/Account/Invoices.vue')
    },
    {
        path: '/Account/logs',
        name: 'logs',
        component: () => import('../views/Account/logs.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Account/login.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    routes: route
});

export default router;